import { NavLink } from "react-router-dom";
import { useState } from "react";

import NavBar from "./other components/Navbar";
import "../style/Home.css";
import "../App.css";
import featuredProducts from "./products/featuredProducts";
import featuredProducts2 from "./products/featuredProducts2";
import featuredProducts3 from "./products/featuredProducts3";
import featuredProducts4 from "./products/featuredProducts4";
import TestimonialsSlider from "./other components/TestimonialsSlider";
import HomeBanner from "./other components/HomeBanner";
import Footer from "./other components/Footer";

import Tools from "./images/tools.png";
import Coinbag from "./images/coinbag.png";
import MagnifyingLens from "./images/magnifyinglens.png";
import Cart from "./images/cart.png";

import KitchenFit from "./images/kitechenfittings.png";
import CupboardFit from "./images/cupboardfittings.png";
import GlassFit from "./images/glassfittings.png";
import AluminiumPro from "./images/aluminiumprofile.png";


const Home = () => {

    const [featuredList, setFeaturedList] = useState(featuredProducts)

    const click1 = () => {
        setFeaturedList(featuredProducts)
    }
    const click2 = () => {
        setFeaturedList(featuredProducts2)
    }
    const click3 = () => {
        setFeaturedList(featuredProducts4)
    }
    const click4 = () => {
        setFeaturedList(featuredProducts3)
    }

    const addToCart = {

    }

    const addToWishList = () => {

    }

    return (
        <>
            <div className="section1Mainmost">
                {/* top line */}
                <div className="aboutMain">
                    {/* top line */}
                    <div className="topBarMain">

                        <div className="topBar">
                            <div className="topBarOne">
                                <p style={{ fontFamily: "'Jost', sans-serif" }} >FREE SHIPPING FOR ALL ORDERS ABOVE ₹ 1,999</p>
                            </div>
                            <div className="topBarTwo">
                                <i className="fa-brands fa-facebook-f"></i>
                                <a style={{ textDecoration: "none", color: "black" }} href="/">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a style={{ textDecoration: "none", color: "black" }} href="https://www.google.com/maps/place/HPZ+WORLDWIDE+PRIVATE+LIMITED/@17.3845779,78.453285,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb9972611be379:0x58f98e4ab2925934!8m2!3d17.3845779!4d78.4558599!16s%2Fg%2F11s8y3t5qf?entry=tts&g_ep=EgoyMDI0MTAyMy4wIPu8ASoASAFQAw%3D%3D">
                                    <i className="fa-brands fa-google"></i>
                                </a>
                                <a style={{ textDecoration: "none", color: "black" }} href="https://wa.me/919440295353">
                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>
                                {/* <i className="fa-brands fa-telegram"></i> */}
                            </div>
                        </div>

                    </div>

                    {/* Navbar */}
                    <NavBar />

                    <div className="secOneMain">
                        <div className="secOneSub">
                            <HomeBanner />
                        </div>
                    </div>





                    {/* section 2 */}

                    <div className="sec2Home">
                        <h2>"DURABLE | RELIABLE | UNBEATABLE"</h2>
                    </div>

                    {/* section 3 */}

                    <div className="sec3Home">

                        <div className="sec3Sub">

                            <h2>Our Categories</h2>

                            <div className="categoryDivs">

                                <div onClick={click1} className="categoryDiv">
                                    {/* <div className="imgCategory"></div> */}
                                    <img src={KitchenFit} alt="" className="categoryImgs" />
                                    <p>Kitchen <br /> Fittings</p>
                                </div>

                                <div onClick={click2} className="categoryDiv">
                                    <img src={CupboardFit} alt="" className="categoryImgs" />
                                    <p>Wardrobe <br /> Fittings</p>
                                </div>

                                <div onClick={click3} className="categoryDiv">
                                    <img src={GlassFit} alt="" className="categoryImgs" />
                                    <p>Glass <br /> Fittings</p>
                                </div>

                                <div onClick={click4} className="categoryDiv">
                                    <img src={AluminiumPro} alt="" className="categoryImgs" />
                                    <p>Aluminium <br /> Profile</p>
                                </div>

                            </div>

                        </div>

                        {/* Section 2 : Featured Products */}

                        <div className="section2Main">

                            <div className="section2">
                                {/* <h2>Featured Products</h2> */}

                                <div className="featuredProducts">
                                    {featuredList.map((data) => {
                                        return (

                                            <NavLink to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black" }} className="productsDiv">
                                                {/* <div className="bgImgProducts" style={{backgroundImage:`url("https://eshantidental.com/wp-content/uploads/2021/05/Aspirating-Syringe-SYRA1.8ml-1-600x447.jpg")`}}> */}
                                                <div className="bgImgProducts" style={{ backgroundImage: `url(${data.image})` }}>
                                                    <button className="discountProducts">
                                                        -{Math.round(((data.actualPrice - data.discountPrice) * 100) / data.actualPrice)}%
                                                    </button>
                                                    <div className="toolsProducts">
                                                        <i className="fa-solid fa-cart-shopping" onClick={(e) => addToCart(e, data.productName, data.productCat, data.inStock, data.actualPrice, data.discountPrice, data.image, data.brandName, data.email)}></i>
                                                        {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                                                        <i className="fa-regular fa-eye"></i>
                                                        <i className="fa-regular fa-heart" onClick={(e) => addToWishList(e, data.productName, data.productCat, data.inStock, data.actualPrice, data.discountPrice, data.image, data.brandName, data.email)}></i>
                                                    </div>
                                                </div>
                                                <div className="detailsProducts">
                                                    <span style={{ fontWeight: "600", padding: "5px 0px" }}>{data.productName}</span>
                                                    <span>{data.brandName}</span>
                                                    <span className="pricingProducts">
                                                        <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                                        <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.discountPrice}</span>
                                                    </span>
                                                </div>

                                            </NavLink>

                                        )
                                    })}

                                </div>
                                <a className="moreBtn" href="/allProducts">SEE MORE</a>


                            </div>

                        </div>
                    </div>


                </div>
            </div>

            {/* WHy choose us */}

            <div className="section3Main">

                <div className="attributesDiv">
                    <h2>Why choose us?</h2>
                    <div className="attributesSub">
                        <div className="attDiv">
                            {/* <i class="fa-solid fa-screwdriver-wrench"></i> */}
                            <img src={Tools} alt="" />
                            <p>Premium Products</p>
                        </div>
                        <div className="attDiv">
                            {/* <i class="fa-solid fa-dollar-sign"></i> */}
                            <img src={MagnifyingLens} alt="" />
                            <p>Trusted Brands</p>
                        </div>
                        <div className="attDiv">
                            {/* <i class="fa-solid fa-cloud"></i> */}
                            <img src={Coinbag} alt="" />
                            <p>Affordable Prices</p>
                        </div>
                        <div className="attDiv">
                            {/* <i class="fa-solid fa-hippo"></i> */}
                            <img src={Cart} alt="" />
                            <p>Seamless Delivery</p>
                        </div>
                    </div>
                </div>

                <div className="testimonialsDiv">
                    <h2>Lets hear from our clients...</h2>
                    <center>
                        <TestimonialsSlider />
                    </center>
                </div>

            </div>

            {/* WHy choose us ends */}

            {/* Track your order */}

            <div className="orderTrackMain" id="trackOrder">
                <div className="orderTrackDiv">
                    <h2 className="reviewHeading" >Track your Order</h2>
                    <span className="tracktxt">To track your order please enter your Order ID in the box below and press the "Track" button.
                        <br />This was given to you on your receipt and in the confirmation email you should have received.</span>
                    <div className="orderInputs">
                        <input type="text" placeholder="Enter Billing Email" />
                        <input type="text" placeholder="Enter Order ID" />
                    </div>
                    <button className="trackBtn">TRACK</button>
                </div>
            </div>

            {/* footer */}
            <Footer />
            {/* footer ends */}

        </>
    )

}

export default Home;