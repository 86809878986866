import { NavLink } from "react-router-dom";
import Logo from "../images/logo.jpg";
import SearchBox from "./Searchbox";
import Cart from "./Cart";
import Menu3 from "./Menu3.js";
import Canopy from "../images/canopylarge.png"

const NavBar = () => {
    return (
        <>
            {/* Navbar */}
            <div className="navbarMain">

                {/* <center>
                    <img src={Canopy} alt="" className="canopyNav" />
                </center> */}

                {/* desktop */}
                <div className="navBar" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>

                    <NavLink style={{ textDecoration: "none" }} to="/">
                        <img src={Logo} alt="logo" className="logoNav" />
                        {/* <span className="logoMaintext">HPZ</span> */}
                    </NavLink>

                    {/* <i className="fa-solid fa-bars menuNav"></i> */}

                    {/* <MenuBar /> */}
                    <SearchBox />


                    <div className="navAccBox">
                        <NavLink to="/login">
                            <i style={{ color: "black" }} className="fa-regular fa-user userIcon"></i>{/*<span className="superTxt"></span> */}
                        </NavLink>

                        <NavLink to="/wishlist" style={{ textDecoration: "none", color: "black" }}>
                            <i className="fa-regular fa-heart"></i><span className="superTxt">0</span>
                        </NavLink>

                        {/* <i className="fa-solid fa-cart-shopping"></i><span className="superTxt">0</span> */}
                        <Cart /><span className="superTxt">0</span>
                    </div>

                </div>

                {/* mobile */}
                <div className="navBarMobile" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>

                    <NavLink style={{ textDecoration: "none" }} to="/">
                        <img src={Logo} alt="logo" className="logoNav logoNavMob" />
                        {/* <span className="logoMaintextMobile">HPZ</span> */}
                    </NavLink>

                    {/* <MenuBar /> */}
                    <Menu3 />

                </div>

            </div>

            {/* <div className="partOneThree" >
                <div className="toggleDiv" >


                    <div className="dropdown">
                        <p className="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Kitchen Fittings
                        </p>
                         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><NavLink className="dropdown-item" to="/services/homeloan" >Home Loan</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/personalloan" >Personal Loan</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/businessloan" >Business Loan</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/carloan" >Car Loan</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/educationloan" >Education Loan</NavLink></li>
                        </ul> 
        </div >

                    <div className="dropdown">
                        <p className="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Wardrobe Fittings
                        </p>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><NavLink className="dropdown-item" to="/services/lifeinsurance" >Life Insurance</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/healthinsurance" >Health Insurance</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/vehicleinsurance" >Vehicle Insurance</NavLink></li>
                        </ul> 
                    </div>

                    <div className="dropdown">
                        <p className="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Glass Fittings
                        </p>
                         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><NavLink className="dropdown-item" to="/services/labourlicense" >Labour License</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/tradelicense" >Trade License</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/gst" >GST Registration</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/foodlicense" >Food License {"(FSSAI)"} </NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/trademarkreg" >Trademark Registration</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/msme" >MSME</NavLink></li>
                        </ul> 
                    </div>


                    <div className="dropdown">
                        <p type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Aluminium Profile
                        </p>
                         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><NavLink className="dropdown-item" to="/services/incometax" >Income Tax</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/accounting" >Accounting</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/services/gstfiling" >GST Filing</NavLink></li>
                        </ul>
                    </div>


                </div > 
        </div >*/}
        </>
    )
}

export default NavBar;