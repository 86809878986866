import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const TestimonialsSlider = () => {
    return (<>
        <Carousel showIndicators={false} width={"90%"} infiniteLoop={true} autoPlay={true} interval={5000} showStatus={false}>

            <div className="testiSub">
                <i class="fa-solid fa-quote-left quoteStart"></i>
                <p>The kitchen fittings we purchased have completely transformed our space. The quality is exceptional, and the team was so helpful in guiding us through the options</p>
                <i class="fa-solid fa-quote-right quoteEnd"></i>
                <div className="testName">
                    <span>Rajesh Mehta</span>
                    <span style={{fontSize:"14px"}}>Mumbai</span>
                </div>
            </div>

            <div className="testiSub">
                <i class="fa-solid fa-quote-left quoteStart"></i>
                <p>I couldn’t be happier with the glass fittings we installed in our home. Sleek design, premium quality, and very reasonably priced. Highly recommend!</p>
                <i class="fa-solid fa-quote-right quoteEnd"></i>
                <div className="testName">
                    <span>Anjali Sharma</span>
                    <span style={{fontSize:"14px"}}>Gurugram, India</span>
                </div>
            </div>

            <div className="testiSub">
                <i class="fa-solid fa-quote-left quoteStart"></i>
                <p>Their products are top-notch and very durable. Perfect for my office interiors. Great customer service too</p>
                <i class="fa-solid fa-quote-right quoteEnd"></i>
                <div className="testName">
                    <span>Mohit Dedhia</span>
                    <span style={{fontSize:"14px"}}>Surat, Gujrat</span>
                </div>
            </div>

            <div className="testiSub">
                <i class="fa-solid fa-quote-left quoteStart"></i>
                <p>Outstanding service and a vast selection of hardware fittings. The team ensured everything was delivered on time and met our expectations perfectly.</p>
                <i class="fa-solid fa-quote-right quoteEnd"></i>
                <div className="testName">
                    <span>Rajender Jain</span>
                    <span style={{fontSize:"14px"}}>Surat, Gujrat</span>
                </div>
            </div>

        </Carousel>
    </>)
}

export default TestimonialsSlider;